<template>
    <AModal
        :visible="visible"
        title="Detail Jadwal Kunjungan Salesman"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        :footer="null">
        <ARow>
            <ACol :span="8">Kode Toko</ACol>
            <ACol>: {{ detail.kode_toko }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Nama Toko</ACol>
            <ACol>: {{ detail.nama_toko }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Nopeg Salesman</ACol>
            <ACol>: {{ detail.nopeg_salesman }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Nama Salesman</ACol>
            <ACol>: {{ detail.nama_salesman }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Kode Distributor</ACol>
            <ACol>: {{ detail.kode_distributor }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Nama Distributor</ACol>
            <ACol>: {{ detail.nama_distributor }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Tanggal Kunjungan</ACol>
            <ACol>: {{ detail.tanggal }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Jenis Kunjungan</ACol>
            <ACol>: {{ detail.jenis_kunjungan }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Tipe Kunjungan</ACol>
            <ACol>: {{ detail.nama_type_kunjungan }}</ACol>
        </ARow>
    </AModal>
</template>
<script>
import {
    defineComponent,
    ref,
} from 'vue'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const detail = ref(props.item)

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        return {
            handleModalCancel,
            detail,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
