<template>
    <ASelect
        v-model:value="valueState"
        placeholder="Pilih Salesman"
        allow-clear
        show-search
        :not-found-content="loading ? undefined : 'Tidak ditemukan'"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (findText = null)"
        @select="() => (findText = null)"
        v-bind="$attrs"
        :loading="loading"
        :show-arrow="true"
        style="width:300px;">
        <ASelectOption v-for="d in lists" :key="d.salesman_id" :label="`${d.salesman_nopeg} - ${d.salesman_name}`">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="highlight(`${d.salesman_nopeg} - ${d.salesman_name}`)"></span>
            <!--eslint-enable-->
        </ASelectOption>
        <template v-if="loading" #notFoundContent>
            <ASpin size="small" />
        </template>
    </ASelect>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, pickBy, values, merge, keyBy } from 'lodash'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        joinOptions: {
            type: [Array],
            default: () => [],
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const lists = ref([])
        const findText = ref(null)
        const loading = ref(false)

        const fetchData = () => {
            loading.value = true
            apiClient.get('/api/filter/salesman-v2', { params: pickBy({
                q: findText.value,
            }) })
                .then(({ data }) => {
                    const options = props.joinOptions.filter(item => item.salesman_id !== undefined)
                    if (options.length > 0) {
                        data = values(merge(keyBy(options, 'salesman_id'), keyBy(data, 'salesman_id')))
                    }

                    lists.value = data.sort((a, b) =>
                        a.salesman_name.toLowerCase() > b.salesman_name.toLowerCase() ? 1 : -1,
                    )

                    // if (!data.find(item => item.salesman_id == props.value)) {
                    //     emit('update:value', null)
                    // }
                })
                .finally(() => {
                    loading.value = false
                })
        }

        onMounted(() => {
            fetchData()
        })

        const onSearch = debounce(value => {
            loading.value = true
            findText.value = value
            fetchData()
        }, 300)

        const highlight = value => {
            return value.replace(new RegExp(findText.value, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        return {
            lists,
            valueState: useVModel(props, 'value'),
            findText,
            highlight,
            onSearch,
            loading,
        }
    },
}
</script>