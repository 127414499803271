<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <ADatePicker
                    style="width:100%;"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ADatePicker
                    style="width:100%;"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"
                    v-model:vendor_id="state.vendor_id"/>
            </ACol>
            <ACol
                v-if="hasRoles([ROLE_TSO, ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_CRM_ATSALESMAN])"
                :xl="6" :md="8" :sm="12">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterSalesmanV2
                    style="width:100%;"
                    mode="multiple"
                    v-model:value="state.params.salesman"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM])"
                    class="mr-3"
                    title="Sync all"
                    type="primary"
                    @click="btnSyncAll()"
                    :loading="state.isSync">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                </AButton>
                <!-- TODO: admin sementara waktu -->
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_DISTRIBUTOR, ROLE_TSO])"
                    class="mr-3"
                    title="Upload Dokumen"
                    type="primary"
                    @click="btnUpload">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                </AButton>
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_DISTRIBUTOR, ROLE_TSO])"
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="btnForm">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Jadwal-Kunjungan-Salesman"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                <template #status="{ text }">
                    <ATag v-if="text" color="#108ee9">Sukses</ATag>
                    <ATag v-else color="grey">Gagal</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Detail">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDetail(record)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Edit">
                            <AButton
                                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_DISTRIBUTOR, ROLE_TSO])"
                                class="button"
                                size="small"
                                @click="btnForm(record)"
                                :disabled="record.is_checkin || record.is_disable_button">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Hapus">
                            <AButton
                                v-if="hasRoles([ROLE_ADMIN_BK, ROLE_TIM_CRM, ROLE_DISTRIBUTOR, ROLE_TSO])"
                                class="button"
                                size="small"
                                @click="btnDelete(record.id)"
                                :disabled="record.is_checkin || record.is_disable_button">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="record.role_sync"
                            title="Sync">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isSync"
                                @click="btnSyncByID(record)">
                                <i class="fa fa-refresh" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- detail -->
        <Detail
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:item="visibleDetailItemModal"/>

        <!-- upload -->
        <Upload
            v-if="visibleUploadModal"
            v-model:visible="visibleUploadModal"
            @success="fetchDataList"/>

        <AModal
            v-model:visible="state.sync.isShow"
            title="Result">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Sukses: ${state.sync.success}`"></AAlert>
            <AAlert type="error">
                <template #message>
                    {{ `Gagal: ${state.sync.failed}` }}
                    <a-tree :tree-data="state.sync.data" default-expand-all/>
                </template>
            </AAlert>
        </AModal>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterToko from '@/components/filter/FilterToko'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterSalesmanV2 from '@/components/filter/FilterSalesmanV2'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import Detail from './Detail'
import Upload from './Upload'
import { includes } from 'lodash'
import moment from 'moment'
import localStorage from 'store'
import {
    hasRoles,
    ROLE_TSO,
    ROLE_ADMIN_BK,
    ROLE_TIM_CRM,
    ROLE_CRM_ATSALESMAN,
    ROLE_DISTRIBUTOR,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterToko,
        FilterDistributor,
        FilterSalesmanV2,
        FormCreateOrUpdate,
        DownloadExcel,
        Detail,
        Upload,
    },
    setup() {
        const errorMessage = ref()
        const user = computed(() => localStorage.get('profile'))
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'kode_toko',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Nopeg Salesman',
                    dataIndex: 'nopeg_salesman',
                },
                {
                    title: 'Nama Salesman',
                    dataIndex: 'nama_salesman',
                },
                {
                    title: 'Tanggal',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Tipe Syncron',
                    dataIndex: 'type_sync',
                },
                {
                    title: 'Visit  Code',
                    dataIndex: 'visit_code',
                },
                {
                    title: 'Status',
                    dataIndex: 'sync_status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: '/api/visit-plan',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isSync: false,
            params: extractQueryParams({
                q: '',
                salesman: [],
                toko: [],
                start_date: null,
                end_date: null,
                distributor: [],
                page: 1,
                "per-page": 10,
            }),
            sync: {
                isShow: false,
                success: 0,
                failed: 0,
                data: [],
            },
            vendor_id: null,
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const disabledStartDate = startValue => {
            if (!startValue || !state.params.end_date) {
                return false
            }
            return startValue.valueOf() > state.params.end_date.valueOf()
        }

        const disabledEndDate = endValue => {
            if (!endValue || !state.params.start_date) {
                return false
            }
            return state.params.start_date.valueOf() >= endValue.valueOf()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isSync = false
                        // hide button Sync in action column except admin
                        item.role_sync = hasRoles(ROLE_ADMIN_BK)
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnDelete = id => {
            Modal.confirm({
                title: 'Konfirmasi hapus jadwal kunjungan salesman',
                content: 'Apakah anda ingin hapus jadwal kunjungan salesman?',
                onOk() {
                    apiClient
                        .delete(`/api/visit-plan/${id}`)
                        .then((response) => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                },
                onCancel() { },
            })
        }

        // sync data all
        const btnSyncAll = () => {
            state.isSync = true

            apiClient
                .post('/api/visit-plan/sync-data-all')
                .then(({ data }) => {
                    state.sync.success = data.success
                    state.sync.failed = data.failed

                    if (data.data) {
                        data.data.forEach((item, index) => {
                            state.sync.data.push({
                                title: item.errors.message,
                                key: `0-${index}`,
                            })
                        })
                    }
                    
                    fetchDataList()
                })
                .finally(() => {
                    state.isSync = false
                    state.sync.isShow = true
                })
        }

        // sync data by id
        const btnSyncByID = (record = null) => {
            record.isSync = true

            apiClient
                .post(`/api/visit-plan/sync-data-by-id/${record.id}`)
                .then(({ data }) => {
                    data.forEach(item => {
                        if (item.errors) {
                            message.error(item.errors.message)
                        }
                    })

                    fetchDataList()
                })
                .catch(({ response: { data } }) => {
                    if (data.length) {
                        Modal.warning({
                            title: data[0].message,
                            content: data[0].detail.message,
                        })
                    }
                })
                .finally(() => {
                    record.isSync = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = item
        }

        // handle detail
        const visibleDetailModal = ref(false);
        const visibleDetailItemModal = ref(null);

        const btnDetail = (item = null) => {
            visibleDetailModal.value = true
            visibleDetailItemModal.value = item
        }

        // handle upload
        const visibleUploadModal = ref(false);

        const btnUpload = () => {
            visibleUploadModal.value = true
        }

        // handle vue
        onMounted(() => {
            // filter untuk menghilangkan column
            if (!hasRoles([ROLE_ADMIN_BK])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['sync_status'], item.dataIndex)) {
                        return item
                    }
                })
            }

            // delete column
            // if (hasRoles([ROLE_TIM_CRM])) {
            //     state.columns = state.columns.filter(item => {
            //         if (!includes(['action', 'sync_status'], item.dataIndex)) {
            //             return item
            //         }
            //     })
            // }

            // delete column action
            if (hasRoles([ROLE_CRM_ATSALESMAN])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }

            if (hasRoles([ROLE_DISTRIBUTOR])) {
                state.vendor_id = user.value.vendor_id
            }

            fetchDataList()
        })

        return {
            visibleFormModal,
            visibleFormItemModal,
            visibleDetailModal,
            visibleDetailItemModal,
            visibleUploadModal,
            btnUpload,
            btnForm, // create or update
            btnDelete,
            btnDetail,
            fetchDataList,
            state,
            queryParams,
            handleTableChange,
            errorMessage,
            btnSyncAll,
            btnSyncByID,
            disabledStartDate,
            disabledEndDate,
            hasRoles,
            ROLE_TSO,
            ROLE_ADMIN_BK,
            ROLE_TIM_CRM,
            ROLE_CRM_ATSALESMAN,
            ROLE_DISTRIBUTOR,
        }
    },
})
</script>
